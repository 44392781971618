(function($) {
    "use strict";
    $(function() {
        function setImage() {
            let $blockWithBackground = $("[data-interchange]");
            $blockWithBackground.each(function() {
                let thisEnv = $(this);
                let string = thisEnv.data("interchange");
                let arr = string.split(";");

                arr.forEach(function(item) {
                    let currArr = item.split(",");

                    let breakpoint = currArr[1];
                    let srcOfImg = currArr[0];

                    if (window.matchMedia(`(min-width:${breakpoint}px)`).matches) {
                        thisEnv.prop("style", `background-image:url(${srcOfImg});`);
                    }
                });
            });
            let $blockImg = $("[data-interchange-img]");
            $blockImg.each(function() {
                let thisEnv = $(this);
                let string = thisEnv.data("interchange-img");
                let arr = string.split(";");

                arr.forEach(function(item) {
                    let currArr = item.split(",");

                    let breakpoint = currArr[1];
                    let srcOfImg = currArr[0];

                    if (window.matchMedia(`(min-width:${breakpoint}px)`).matches) {
                        thisEnv.prop("src", srcOfImg);
                    }
                });
            });
        }
        setImage();
        $(window).resize(function() {
            setImage();
        });
    });
})(jQuery);