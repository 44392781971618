(function($) {
    "use strict";
    $(function() {

        const $header = $("#header");
        if (!$header.length)
            return false;
        const $headerContainer = $(".header-container")
        let headerHeight = $header.outerHeight();

        const $headerRow = $(".header__row");
        let topOffsetOfMenu = $headerRow.offset().top + $headerRow.outerHeight(true);

        console.log(topOffsetOfMenu);
        console.log($headerRow.outerHeight(true));

        $(window).on("scroll", function() {


            let scrollOfTop = $(this).scrollTop();


            if (window.matchMedia("(max-width: 576px)").matches) {
                topOffsetOfMenu = 0;
            }


            if (scrollOfTop > topOffsetOfMenu) {
                $headerContainer.height(headerHeight);
                $header.addClass("header__sticky");

                if (!$header.hasClass("header__inner")) {
                    $("#header-logo").attr("src", $("#header-logo").attr("data-sticky-logo"));
                }

            } else {
                $headerContainer.height(0);
                $header.removeClass("header__sticky");

                if (!$header.hasClass("header__inner")) {
                    $("#header-logo").attr("src", $("#header-logo").attr("data-sticky-default-logo"));
                }


            }
        });

    });
})(jQuery);