(function($) {
    "use strict";
    $(function() {

        if ($("#contacts__map").length) {


            ymaps.ready(function() {
                var myMap = new ymaps.Map('contacts__map', {
                    center: [56.562549, 36.511432],
                    zoom: 14
                }, {
                    searchControlProvider: 'yandex#search'
                });
                myMap.geoObjects.add(new ymaps.Placemark([56.575364, 36.505549], {

                    balloonContent: 'Вертолетная площадка Завидово (Треуголка)',
                    iconCaption: 'Вертолетная'
                }, {
                    preset: 'islands#blueAirportCircleIcon',

                })).add(new ymaps.Placemark([56.556927, 36.566903], {

                    balloonContent: 'Аэропарк Завидово',
                    iconCaption: 'Аэропарк Завидово'
                }, {
                    preset: 'islands#blueAirportCircleIcon',

                }));


                myMap.behaviors.disable('scrollZoom');

                var ctrlKey = false;
                var ctrlMessVisible = false;
                var timer;

                // Отслеживаем скролл мыши на карте, чтобы показывать уведомление
                myMap.events.add(['wheel', 'mousedown'], function(e) {
                    if (e.get('type') == 'wheel') {
                        if (!ctrlKey) { // Ctrl не нажат, показываем уведомление
                            $('#ymap_ctrl_display').fadeIn(300);
                            ctrlMessVisible = true;
                            clearTimeout(timer); // Очищаем таймер, чтобы продолжать показывать уведомление
                            timer = setTimeout(function() {
                                $('#ymap_ctrl_display').fadeOut(300);
                                ctrlMessVisible = false;
                            }, 1500);
                        } else { // Ctrl нажат, скрываем сообщение
                            $('#ymap_ctrl_display').fadeOut(100);
                        }
                    }
                    if (e.get('type') == 'mousedown' && ctrlMessVisible) { // Скрываем уведомление при клике на карте
                        $('#ymap_ctrl_display').fadeOut(100);
                    }
                });

                // Обрабатываем нажатие на Ctrl
                $(document).keydown(function(e) {
                    if (e.which === 17 && !ctrlKey) { // Ctrl нажат: включаем масштабирование мышью
                        ctrlKey = true;
                        myMap.behaviors.enable('scrollZoom');
                    }
                });
                $(document).keyup(function(e) { // Ctrl не нажат: выключаем масштабирование мышью
                    if (e.which === 17) {
                        ctrlKey = false;
                        myMap.behaviors.disable('scrollZoom');
                    }
                });


            });
        }

    });
})(jQuery);