import "@fancyapps/fancybox";
import "magnify";
import "./foundation.js";
import "./map.js";
import "./fontAwesome.js";
import "./carousels.js";
import "./interchange";
import "./stickyHeader";
import "./jquery.inputmask.min.js";


import "../scss/style.scss";
import { htmlPrefilter } from "jquery";
(function($) {
    "use strict";
    $(function() {
        // your code here

        $(".header__burger").on("click", function() {
            $("#menu").toggleClass("show-for-medium");
            $(this).toggleClass("active");
            $("#header__logo").toggleClass("hide");
            $("#feedback_header").toggleClass("hide");
            $("#header").toggleClass("active");
            $("body").toggleClass("modal-open");

        })
    });



    $(document).on("click", ".contacts__transport-item", function() {
        let id = $(this).attr("data-toggle");
        let descThis = $(".contacts__ride").not(".hide");

        $(".contacts__transport-item.active").removeClass("active");
        descThis.addClass("hide");
        $(this).addClass("active");
        $("#" + id).removeClass("hide")
    });

    // $("#fedback__form").on("submit", function(e) {
    //     e.preventDefault();
    //     $(this).hide();
    //     $("#success").removeClass("hide");

    // });


    $("[autocomplete='tel']").inputmask("+7 (999) 999-99-99");


    if (window.matchMedia(`(min-width:960px)`).matches) {
        $('.zoom').magnify();
    }


    if (window.matchMedia(`(max-width:960px)`).matches) {
        var infraMobContainer = new Foundation.Reveal($("#mobile-popup-infra"));
    }



    createPont();


    function createPont() {
        $(".genplan__points").each(function(item) {
            let pointer = $(this).attr("data-pointer");
            if (pointer) {
                pointer = pointer.split(',');

                for (let i in pointer) {
                    let point = pointer[i];


                    let element = document.getElementById(point);
                    let elementId = element.getAttribute("id");
                    let pos = element.getBBox();
                    let g = document.createElementNS('http://www.w3.org/2000/svg', 'g');
                    let rect = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
                    rect.setAttribute('x', pos.x);
                    rect.setAttribute('y', pos.y);


                    if (point.indexOf('vk') > -1 || point.indexOf('vt') > -1 || point.indexOf('vb') > -1 || point.indexOf('vu') > -1) {
                        rect.setAttribute('width', "60");
                        rect.setAttribute('height', "60");
                    } else {
                        rect.setAttribute('width', "35");
                        rect.setAttribute('height', "35");
                    }

                    rect.setAttribute('fill', "transparent");

                    element.parentNode.insertBefore(g, document.getElementById(point).nextSibling);
                    g.setAttribute('id', "p-" + point);
                    g.appendChild(rect);
                    g.addEventListener("click", function() {



                        if (window.matchMedia(`(max-width:960px)`).matches) {
                            let html = $("#" + "pp-" + point).html();

                            let wrapper = $("<div />", {
                                class: "infra__tooltips-item",
                                html: html
                            });
                            $("#mobile-popup-infra-content").html(wrapper);
                            infraMobContainer.open();
                        } else {
                            $(document).find(".infra__tooltips-item").hide();
                            $("#" + "pp-" + point).show();
                        }

                    });
                }
            }
        });
    }


    if (window.matchMedia(`(max-width:959px)`).matches) {


        $(".genplan__points").on("click", function() {


            let pointer = $(this).attr("data-pointer");
            console.log(pointer);
            if (pointer) {
                pointer = pointer.split(',');
                let point = pointer[pointer.length - 1];

                let html = $("#" + "pp-" + point).html();
                // $("#mobile-popup-infra-content").empty();
                let wrapper = $("<div />", {
                    class: "infra__tooltips-item",
                    html: html
                });

                $("#mobile-popup-infra-content").html(wrapper);
                infraMobContainer.open();
            }

        });

    } else {

        $(".genplan__points").on("click", function() {
            let pointer = $(this).attr("data-pointer");
            if (pointer) {
                pointer = pointer.split(',');
                let point = pointer[pointer.length - 1];

                $(document).find(".infra__tooltips-item").hide();

                $("#" + "pp-" + point).show();

            }

        });

    }




    $(document).on("click", ".close__genplan-item", function() {
        $(document).find(".infra__tooltips-item").hide();

    });



})(jQuery);